import { initializePaddle } from '@paddle/paddle-js';
var paddle;
initializePaddle({ environment: 'sandbox', seller: Number(process.env.PADDLE_VENDOR_ID) }).then((paddleInstance) => {
    paddle = paddleInstance;
});

document.addEventListener('DOMContentLoaded', function() {
    const inputs = Array.from(
        document.querySelectorAll('input[name=pem_file], input[name=password]')
    );

    const inputListener = e => {
        inputs
            .filter(i => i !== e.target)
            .forEach(i => (i.required = !e.target.value.length));
    };

    inputs.forEach(i => i.addEventListener('input', inputListener));
});

var paddle_btn = document.getElementById('paddle_btn')
if (paddle_btn) {
    paddle_btn.addEventListener('click', function() {
        var itemsList = [
            {
                priceId: 'pri_01hf0xmf6aaeb7d398nx3jzsz6',
                quantity: 1
            },
        ];

        console.log(itemsList);
        paddle?.Checkout.open({
            settings: {
                displayMode: "overlay",
                theme: "light",
                locale: "en"
            },
            items: itemsList
        });
    });
}
